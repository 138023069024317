import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "super-simplicity-with-react-hooks"
    }}>{`Super simplicity with React Hooks`}</h1>
    <Vimeo id={424597872} mdxType="Vimeo" />
    <p>{`Hooks launched with great fanfare and community excitement in February 2019. A
whole new way to write React components.`}</p>
    <p>{`Not a fundamental change, the React team would say, but a mind shift after all.
A change in how you think about state, side effects, and component structure.
You might not like hooks at first. With a little practice they're going to feel
more natural than the component lifecycle model you're used to now.`}</p>
    <p>{`Hooks exist to:`}</p>
    <ul>
      <li parentName="ul">{`help you write less code for little bits of logic`}</li>
      <li parentName="ul">{`help you move logic out of your components`}</li>
      <li parentName="ul">{`help you reuse logic between components`}</li>
    </ul>
    <p>{`Some say it removes complexity around the `}<inlineCode parentName="p">{`this`}</inlineCode>{` keyword in JavaScript and I'm
not sure that it does. Yes, there's no more `}<inlineCode parentName="p">{`this`}</inlineCode>{` because everything is a
function, that's true. But you replace that with passing a lot of function
arguments around and being careful about function scope.`}</p>
    <p>{`My favorite React Hooks magic trick is that you can and should write your own.
Reusable or not, a custom hook almost always cleans up your code.`}</p>
    <p>{`Oh and good news! Hooks are backwards compatible. You can write new stuff with
hooks and keep your old stuff unchanged. 👌`}</p>
    <p>{`In this section we're going to look at the core React hooks, talk about the
most important hook for dataviz, then refactor our big example from earlier to
use hooks.`}</p>
    <h2 {...{
      "id": "usestate-useeffect-and-usecontext"
    }}>{`useState, useEffect, and useContext`}</h2>
    <Vimeo id={424597772} mdxType="Vimeo" />
    <p>{`React comes with `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html"
      }}>{`a bunch of basic and advanced hooks`}</a>{`.`}</p>
    <p>{`The core hooks are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`useState`}</inlineCode>{` for managing state`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`useEffect`}</inlineCode>{` for side-effects`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`useContext`}</inlineCode>{` for React's context API`}</li>
    </ul>
    <p>{`Here's how to think about them in a nutshell 👇`}</p>
    <h3 {...{
      "id": "usestate"
    }}>{`useState`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook replaces pairs of state getters and setters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class myComponent extends React.Component {
  state = {
    value: "default",
  }

  handleChange = (e) =>
    this.setState({
      value: e.target.value,
    })

  render() {
    const { value } = this.state

    return <input value={value} onChange={handleChange} />
  }
}
`}</code></pre>
    <p>{`👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const myComponent = () => {
  const [value, setValue] = useState("default")

  const handleChange = (e) => setValue(e.target.value)

  return <input value={value} onChange={handleChange} />
}
`}</code></pre>
    <p>{`Less code to write and understand.`}</p>
    <p>{`In a class component you:`}</p>
    <ul>
      <li parentName="ul">{`set a default value`}</li>
      <li parentName="ul">{`create an `}<inlineCode parentName="li">{`onChange`}</inlineCode>{` callback that fires `}<inlineCode parentName="li">{`setState`}</inlineCode></li>
      <li parentName="ul">{`read value from state before rendering etc.`}</li>
    </ul>
    <p>{`Without modern fat arrow syntax you might run into trouble with binds.`}</p>
    <p>{`The hook approach moves that boilerplate to React's plate. You call `}<inlineCode parentName="p">{`useState`}</inlineCode>{`.
It takes a default value and returns a getter and a setter.`}</p>
    <p>{`You call that setter in your change handler.`}</p>
    <p>{`Behind the scenes React subscribes your component to that change. Your
component re-renders.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/usestate-exercise-uxie6?file=/src/App.js",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h3 {...{
      "id": "useeffect"
    }}>{`useEffect`}</h3>
    <p><inlineCode parentName="p">{`useEffect`}</inlineCode>{` replaces the `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`, `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{`,
`}<inlineCode parentName="p">{`shouldComponentUpdate`}</inlineCode>{`, `}<inlineCode parentName="p">{`componentWillUnmount`}</inlineCode>{` quadfecta. It's like a
trifecta, but four.`}</p>
    <p>{`Say you want a side-effect when your component updates, like make an API call.
Gotta run it on mount and update. Want to subscribe to a DOM event? Gotta
unsubscribe on unmount.`}</p>
    <p>{`Wanna do all this only when certain props change? Gotta check for that.`}</p>
    <p>{`Class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class myComp extends Component {
  state = {
      value: 'default'
    }

    handleChange = (e) => this.setState({
      value: e.target.value
    })

    saveValue = () => fetch('/my/endpoint', {
        method: 'POST'
        body: this.state.value
    })

    componentDidMount() {
        this.saveValue();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.saveValue()
        }
    }

    render() {
      const { value } = this.state;

      return <input value={value} onChange={handleChange} />
    }
}
`}</code></pre>
    <p>{`👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const myComponent = () => {
  const [value, setValue] = useState('default');

  const handleChange = (e) => setValue(e.target.value)
  const saveValue = () => fetch('/my/endpoint', {
        method: 'POST'
        body: value
    })

    useEffect(saveValue, [value]);

  return <input value={value} onChange={handleChange} />
}
`}</code></pre>
    <p>{`So much less code!`}</p>
    <p><inlineCode parentName="p">{`useEffect`}</inlineCode>{` runs your function on `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{`.
And that second argument, the `}<inlineCode parentName="p">{`[value]`}</inlineCode>{` part, tells it to run only when `}<inlineCode parentName="p">{`value`}</inlineCode>{`
changes.`}</p>
    <p>{`No need to double check with a conditional. If your effect updates the
component itself through a state setter, the second argument acts as a
`}<inlineCode parentName="p">{`shouldComponentUpdate`}</inlineCode>{` of sorts.`}</p>
    <p>{`When you return a method from `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, it acts as a `}<inlineCode parentName="p">{`componentWillUnmount`}</inlineCode>{`.
Listening to, say, your mouse position looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [mouseX, setMouseX] = useState()
const handleMouse = (e) => setMouseX(e.screenX)

useEffect(() => {
  window.addEventListener("mousemove", handleMouse)
  return () => window.removeEventListener(handleMouse)
})
`}</code></pre>
    <p>{`Neat 👌`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/useeffect-exercise-0x1x8",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h3 {...{
      "id": "usecontext"
    }}>{`useContext`}</h3>
    <p><inlineCode parentName="p">{`useContext`}</inlineCode>{` cleans up your render prop callbacky hell.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const SomeContext = React.createContext()

// ...

<SomeContext.Consumer>
  {state => ...}
</SomeContext.Consumer>
`}</code></pre>
    <p>{`👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const state = useContext(SomeContext)
`}</code></pre>
    <p>{`Context state becomes just a value in your function. React auto subscribes to
all updates.`}</p>
    <p>{`And those are the core hooks. useState, useEffect, and useContext. You can use
them to build almost everything. 👌`}</p>
    <h1 {...{
      "id": "react-d3-and-hooks"
    }}>{`React, D3, and hooks`}</h1>
    <p>{`Now that you know the basics of hooks, and you've got the mental models of combining D3 and React for modern dataviz, let's try an exercise. Learn how to combine that knowledge into using React, D3, and hooks for dataviz.`}</p>
    <h2 {...{
      "id": "blackbox-components-with-hooks"
    }}>{`Blackbox components with hooks`}</h2>
    <p>{`We'll start with blackbox components. Take the final blackbox example below and refactor it to using hooks.`}</p>
    <p>{`You'll need the `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` hook to reference a rendered `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` element and the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` hook to trigger D3 rendering when the component changes. Don't forget to update React version to latest.`}</p>
    <p>{`Try solving it yourself before watching my video. Helps you learn :)`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/5v21r0wo4x",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h3 {...{
      "id": "my-solution"
    }}>{`My solution`}</h3>
    <Vimeo id={429150499} mdxType="Vimeo" />
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-axis-hooks-df304",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h3 {...{
      "id": "my-used3-hook-for-blackbox-components"
    }}>{`my useD3 hook for blackbox components`}</h3>
    <p>{`Even better than doing it yourself is using the ready-made `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` hook I opensourced for you :)`}</p>
    <Vimeo id={424597604} mdxType="Vimeo" />
    <p>{`Read the full docs at `}<a parentName="p" {...{
        "href": "https://d3blackbox.com/"
      }}>{`d3blackbox.com`}</a></p>
    <p>{`It works as a combination of `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`. Hooks into component
re-renders, gives you control of the anchor element, and re-runs your D3 render
function on every component render.`}</p>
    <p>{`You use it like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { useD3 } from "d3blackbox"
function renderSomeD3(anchor) {
  d3.select(anchor)
  // ...
}

const MyD3Component = ({ x, y }) => {
  const refAnchor = useD3((anchor) => renderSomeD3(anchor))
  return <g ref={refAnchor} transform={\`translate(\${x}, \${y})\`} />
}
`}</code></pre>
    <p>{`You'll see how this works in more detail when we build the big example project. We'll use `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` to build axes.`}</p>
    <p>{`Here's how the above example looks when you use `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` ✌️`}</p>
    <Vimeo id={429150604} mdxType="Vimeo" />
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-axis-used3-prq3g",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "full-integration-components-with-hooks"
    }}>{`Full integration components with hooks`}</h2>
    <p>{`The core mental shift with hooks comes in where your D3 code lives 👉 in the main render.`}</p>
    <p>{`Where we used to spread D3 code around the whole component class, hooks let us do it all in the main render method. Because that's all there is to our component - the main render method.`}</p>
    <p>{`Anything bound to component lifecycle goes into `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, anything we need every time, goes in the function body. When a lot of logic builds up, extract it to a custom hook.`}</p>
    <p>{`If you're worried about performance or have a large dataset 👉 wrap in `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{`.`}</p>
    <p>{`Take this Scatterplot example from before and try to refactor it using hooks. After you gave it a shot, watch the video to check my solution.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/j73xlyr8v5",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <Vimeo id={429154818} mdxType="Vimeo" />
    <iframe {...{
      "src": "https://codesandbox.io/embed/scatterplot-hooks-y4un7",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h3 {...{
      "id": "usememo-is-your-new-best-dataviz-friend"
    }}>{`useMemo is your new best dataviz friend`}</h3>
    <Vimeo id={424597707} mdxType="Vimeo" />
    <p>{`My favorite hook for making React and D3 work together is `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{`. It's like
a combination of `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useState`}</inlineCode>{`.`}</p>
    <p>{`Remember how the rest of this course focused on syncing D3's internal state
with React's internal state and complications around large datasets and
speeding up your D3 code to avoid recomputing on every render?`}</p>
    <p>{`All that goes away with `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` – it memoizes values returned from a function
and recomputes them when particular props change. Think of it like a cache.`}</p>
    <p>{`Say you have a D3 linear scale. You want to update its range when your
component's width changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function MyComponent({ data, width }) {
    const scale = useMemo(() =>
        d3.scaleLinear()
            .domain([0, 1])
            .range([0, width])
    ), [width])

    return <g> ... </g>
}
`}</code></pre>
    <p><inlineCode parentName="p">{`useMemo`}</inlineCode>{` takes a function that returns a value to be memoized. In this case
that's the linear scale.`}</p>
    <p>{`You create the scale same way you always would. Initiate a scale, set the
domain, update the range. No fancypants trickery.`}</p>
    <p><inlineCode parentName="p">{`useMemo`}</inlineCode>{`'s second argument works much like useEffect's does: It tells React
which values to observe for change. When that value changes, `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` reruns
your function and gets a new scale.`}</p>
    <p>{`Don't rely on `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` running only once however. Memoization is meant as a
performance optimization, a hint if you will, not as a syntactical guarantee of
uniqueness.`}</p>
    <p>{`And that's exactly what we want. No more futzing around with
`}<inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` and `}<inlineCode parentName="p">{`this.state`}</inlineCode>{`. Just `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` and leave the rest
to React. ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      